import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'RBH',
    lpAddresses: {
      97: '0x29b284b05dac984a01134eFE26F6cD2b920fdB45',
      56: '0x1F88F4C341365Bf67A96F4F01362EafC35e9dE1e', // AUTO-BNB LP
    },
    tokenSymbol: 'RBH',
    tokenAddresses: {
      97: '0x29b284b05dac984a01134eFE26F6cD2b920fdB45',
      56: '0xD5779F2F9D7D239228e4e78bC78F50768661A081',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  // {
  //   pid: 1,
  //   risk: 5,
  //   isTokenOnly: true,
  //   lpSymbol: 'PRV',
  //   lpAddresses: {
  //     97: '0x29b284b05dac984a01134eFE26F6cD2b920fdB45',
  //     56: '0x7762A14082Ab475C06D3868B385E46aE27017231', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'PRV',
  //   tokenAddresses: {
  //     97: '0x29b284b05dac984a01134eFE26F6cD2b920fdB45',
  //     56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },

  {
    pid: 2,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'RBH-BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x5b9f811D38fc6C1017E0774C552235b33F564DEb', // AUTO-BNB LP
    },
    tokenSymbol: 'RBH',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xD5779F2F9D7D239228e4e78bC78F50768661A081',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 3,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'RBH-BUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x1F88F4C341365Bf67A96F4F01362EafC35e9dE1e', // AUTO-BNB LP
    },
    tokenSymbol: 'RBH',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xD5779F2F9D7D239228e4e78bC78F50768661A081',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 7,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 5,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0', // AUTO-BNB LP
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 8,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USDC-BUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1', // AUTO-BNB LP
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 6,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'PRV-BUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xea275c14414de38f5c19c1e3559af032cc1facf4', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 4,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'PRV-BNB',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xCd18e1151a4831e5EbCEcC611369123012674253', // AUTO-BNB LP
    },
    tokenSymbol: 'PRV',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
]

export default farms
