export default {
  cake: {
    56: '0xD5779F2F9D7D239228e4e78bC78F50768661A081',
    97: '0x29b284b05dac984a01134eFE26F6cD2b920fdB45',
  },
  masterChef: {
    56: '0xd4DC714a68638ffc5EC24441FE37e9dDa677467a',
    97: '0xa24853c00DE8d35A83fe435770dda16fEEa68EE7',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  refferal: {
    56: '0x4f8012DE3dCF0ba7089cEe58b8e9124DA70D0E81',
    97: '0x08d49215Be16bd8593c046119A5631Ec668AB99b',
  },
  moneyWheel: {
    56: '0xc76aC78efb149Dd689f1e64147e7F8D6150A9C0a',
    97: '',
  },
}
